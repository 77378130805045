// src/orderReducer.js
const initialState = { };
  
  const orderReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_ORDER_DETAILS':
        return {
          ...state,
          ...action.payload,
        };
      default:
        return state;
    }
  };
  
  export default orderReducer;
  