// src/store.js
import { createStore, combineReducers } from 'redux';
import orderReducer from './orderReducer';

const rootReducer = combineReducers({
  orderDetails: orderReducer,
});

const store = createStore(rootReducer);

export default store;
